/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.line-title {
    display: inline-block;
    border-bottom: solid #276f4e 3px;
    padding-left: 20px;
    min-width: 200px;
    margin-bottom: 1em;
    color: #000000;
    margin: 0.2em;
    font-size: 1.5em;
    margin-bottom: 0.8em;
}

.padding-box {
  padding: 1.5em;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #f3cc50;
}

::ng-deep .mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: black !important;
}

::ng-deep .mat-checkbox:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
  background-color: black !important;
}
